<template>
  <div>
    <el-select
      :value="value"
      @change="onChange"
      filterable
    >
      <el-option
        v-for="account in allAccount"
        :label="account.accountName"
        :value="account.accountId"
        :key="account.accountId"
      ></el-option>
    </el-select>
    <span class="tips">{{ credentialName }}</span>
  </div>
</template>
<script>
import { getAllAccount } from '@/api/multiPlatform';
export default {
  props: {
    value: String,
    platform: String
  },
  data() {
    return {
      allAccount: [],
    };
  },
  computed: {
    credentialName() {
      if (!this.value) return;
      const accountInfo = this.allAccount.find((item) => item.accountId == this.value) || {};
      return accountInfo.credentialName;
    },
  },
  methods: {
    onChange(val){
      this.$emit('input', val)
    },
    getAllAccount() {
      let params = {
        platform: this.platform
      }
      getAllAccount(params).then((res) => {
        this.allAccount = res.data;
      });
    },
  },
  watch: {
    platform:{
      immediate: true,
      handler(){
        if(this.platform){
          this.getAllAccount()
        }
      }
    }
  }
};
</script>
<style scoped>
.tips{
  color: #999;
  font-size: 12px;
  margin-left: 10px;
}</style>