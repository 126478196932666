<template>
  <div>
    <el-select
      v-model="changeValue"
      class="w-full"
      :disabled="disabled"
      filterable
      remote
      :remote-method="getSiteListDebounce"
      value-key="id"
      clearable
      :loading="loading"
      default-first-option
      placeholder="请选择站点"
      @change="onChange"
    >
      <el-option
        v-for="site in list"
        :label="site.name"
        :value="site"
        :key="site.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import _ from 'lodash';
import { searchSites } from '@/api/multiPlatform';
export default {
  props: {
    disabled: Boolean,
    value: Object,
  },
  data() {
    this.getSiteListDebounce = _.debounce(this.getSiteList, 300);
    return {
      list: [],
      loading: false,
      changeValue: {},
    };
  },
  methods: {
    //
    getSiteList(query) {
      if (query) {
        let params = {
          keyword: query,
        };
        this.loading = true;
        searchSites(params)
          .then((res) => {
            this.list = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onChange() {
      this.$emit('input', this.changeValue);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.changeValue = this.value;
        if (this.value) {
          const index = this.list.findIndex((item) => item.id == this.value.id);
          if (index == -1) {
            this.list.push(this.value);
          }
        }
      },
    },
  },
};
</script>
