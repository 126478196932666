<template>
  <div>
    <el-radio-group
      :value="value"
      @input="onInput"
      @change="onChange"
    >
      <el-radio label="default_sale">使用默认商品</el-radio>
      <el-radio label="assign_sale">使用指定商品</el-radio>
      <el-radio label="clone_sale">使用默认商品克隆</el-radio>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    onChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>
