<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      @change="onChange"
      filterable
    >
      <el-option
        v-for="pixel in pixels"
        :key="pixel.id"
        :value="pixel.id"
        :label="pixel.name"
      ></el-option>
    </el-select>
    <i
      class="el-icon-refresh refresh text-[#409EFF]"
      @click="getAccountPixels"
    ></i>
  </div>
</template>
<script>
import { getAccountPixels } from '@/api/multiPlatform';

export default {
  props: ['value', 'accountId', 'platform'],
  data() {
    return {
      pixels: [],
      loading: false,
    };
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
    getAccountPixels() {
      let params = {
        accountId: this.accountId,
        platform: this.platform,
      };
      this.loading = true;
      getAccountPixels(params)
        .then((res) => {
          this.pixels = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    accountId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit('input', undefined);
          this.getAccountPixels();
        }
      },
    },
  },
};
</script>
<style scope>
.refresh {
  margin-left: 10px;
  /* color: #409EFF; */
  cursor: pointer;
}
</style>
