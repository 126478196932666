<template>
  <el-dialog
    custom-class="cus-column-dialog-muliplatform common-dialog"
    width="800px"
    title="编辑自定义列"
    :visible="visible"
    @close="onClose"
    @open="onOpen"
    :append-to-body="true"
    v-loading="loading"
  >
    <div class="top dp-flex justify-btw">
      <el-input
        size="small"
        placeholder="请输入"
        v-model="searchIpt"
        clearable
      >
        <!-- <template slot="append"><i class="el-icon-search"></i></template> -->
      </el-input>
    </div>
    <div class="center dp-flex justify-btw pr">
      <el-tabs tab-position="left">
        <el-tab-pane
          v-for="(item, index) in allData"
          :key="item.level"
          :label="item.levelName"
        >
          <div class="dp-flex h100">
            <div class="flex-1 checkBox">
              <check-all
                :allCheckboxDatas="(item.columns || []).filter((item) => item.filedName.includes(searchIpt))"
                :checkedInitDatas="checkedData[item.level]"
                keyName="filedKey"
                name="filedName"
                @post-checked-datas="(val) => getCheckedDatas(val, item.level)"
              ></check-all>
            </div>

            <div class="flex-1 bg-f drag-area h100 overflow-auto ml-10 checkBox">
              <cus-drag
                ref="dragRef"
                :initList="checkDataObj[item.level]"
                keyName="filedKey"
                name="filedName"
                @del-item="(item) => handleDelItem(item, item.level)"
                @sort-change="(arr) => sortChange(arr, item.level)"
              ></cus-drag>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-form
      :model="formData"
      inline
      class="mt-20 ml-10"
      ref="formRef"
    >
      <el-form-item
        label="名称"
        prop="name"
        :rules="{ message: '请输入名称', required: true }"
      >
        <el-input
          v-model="formData.name"
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onClose"
        >取 消</el-button
      >
      <el-button
        size="small"
        type="primary"
        @click="handleConfirm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import checkAll from '@/views/multiPlatformAds/components/cusColumnDialog/checkAll.vue';
import cusDrag from '@/views/multiPlatformAds/components/cusColumnDialog/cusDrag.vue';
// import searchShowArea from './searchShowArea';
import { getLevelColumns, saveMyColumn } from '@/api/multiPlatform.js';
const notHasKeys = ['impressions', 'ctr', 'cpm', 'inlineLinkClickCtr', 'costPerInlineLinkClick'];
export default {
  components: {
    checkAll,
    cusDrag,
    // searchShowArea,
  },
  props: {
    visible: Boolean,
    data: {
      // 选择的数据
      type: Object,
      default: () => ({
        name: '',
        columns: {
          campaign: [],
          adset: [],
          ad: [],
        },
      }),
    },
  },
  data() {
    return {
      searchIpt: '',
      allData: {
        campaign: {},
        adset: {},
        ad: {},
      },
      checkedData: {
        campaign: [],
        adset: [],
        ad: [],
      },
      formData: {},
      loading: false,
    };
  },
  computed: {
    checkDataObj() {
      let campaign = this.checkedData.campaign.map((item) => {
        return (this.allData.campaign.columns || []).filter((ele) => ele.filedKey == item)[0];
      });
      let adset = this.checkedData.adset.map((item) => {
        return (this.allData.adset.columns || []).filter((ele) => ele.filedKey == item)[0];
      });
      let ad = this.checkedData.ad.map((item) => {
        return (this.allData.ad.columns || []).filter((ele) => ele.filedKey == item)[0];
      });

      return {
        campaign,
        adset,
        ad,
      };
    },
  },
  methods: {
    onOpen() {
      this.formData = {
        name: this.data.name || '',
      };
      if (this.data.id) {
        this.checkedData = {
          campaign: this.data.columns.campaign.map((ele) => ele.filedKey),
          adset: this.data.columns.adset.map((ele) => ele.filedKey),
          ad: this.data.columns.ad.map((ele) => ele.filedKey),
        };
      } else {
       
        this.checkedData = {
          campaign: this.allData.campaign.columns
            .map((ele) => ele.filedKey)
            .filter((item) => !notHasKeys.includes(item)),
          adset: this.allData.adset.columns.map((ele) => ele.filedKey).filter((item) => !notHasKeys.includes(item)),
          ad: this.allData.ad.columns.map((ele) => ele.filedKey).filter((item) => !notHasKeys.includes(item)),
        };
      }
    },
    onClose() {
      this.$emit('update:visible', false);
    },
    getCheckedDatas(val, level) {
      this.$set(this.checkedData, level, val);
    },
    handleDelItem(item, level) {
      const index = this.checkedData[level].findIndex((ele) => ele == item.filedKey);
      this.checkedData[level].splice(index, 1);
    },
    sortChange(arr, level) {
      let keys = arr.map((item) => item.filedKey);
      this.$set(this.checkedData, level, keys);
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // 保存
          let campaign = this.checkDataObj.campaign.length ? this.checkDataObj.campaign : this.allData.campaign.columns;
          let adset = this.checkDataObj.adset.length ? this.checkDataObj.adset : this.allData.adset.columns;
          let ad = this.checkDataObj.ad.length ? this.checkDataObj.ad : this.allData.ad.columns;
          let params = {
            id: this.data.id,
            name: this.formData.name,
            sysType: 'MULTI_PLATFORM',
            columns: [...campaign, ...adset, ...ad],
          };
          this.loading = true;
          saveMyColumn(params)
            .then(() => {
              this.$message.success('设置成功');
              this.$emit('updateSuccess');
              this.onClose();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  mounted() {
    let params = {
      sysType: 'MULTI_PLATFORM',
    };

    getLevelColumns(params).then((res) => {
      let allData = {
        campaign: res.data.find((ele) => ele.level == 'campaign'),
        adset: res.data.find((ele) => ele.level == 'adset'),
        ad: res.data.find((ele) => ele.level == 'ad'),
      };

      this.allData = allData;
    });
  },
};
</script>

<style lang="scss">
@import '@/styles/commonly';
.cus-column-dialog-muliplatform {
  .el-dialog__body {
    background-color: #f5f5f7;
  }
  .top {
    .el-input {
      width: 200px;
      margin-left: 100px;
      margin-bottom: 10px;
    }
    & > span {
      margin-right: 80px;
      margin-top: 10px;
    }
    i.el-icon-rank {
      vertical-align: middle;
    }
  }
  .center {
    height: 450px;

    .el-tabs {
      width: 100%;
      .el-tabs__content {
        flex: 1;
        padding-left: 20px;
        height: 100%;
        overflow: hidden;
      }
    }
    .el-tab-pane {
      height: 100%;
    }

    .bd-set {
      border: 1px solid rgb(218, 220, 221);
      border-radius: 4px;
    }

    // // 拖拽
    .drag-area {
      width: 300px;
      @extend .bd-set;
    }
    .checkBox {
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #eee;
    }
  }
  .drag-area {
    .item {
      padding: 6px 10px;
      border-bottom: 1px solid rgb(238, 238, 238);
    }
  }
}
</style>
