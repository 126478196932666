import { render, staticRenderFns } from "./pixel.vue?vue&type=template&id=131a45fe"
import script from "./pixel.vue?vue&type=script&lang=js"
export * from "./pixel.vue?vue&type=script&lang=js"
import style0 from "./pixel.vue?vue&type=style&index=0&id=131a45fe&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports