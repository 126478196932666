<template>
  <div>
    <div>
      <el-input
        :value="saleInfo ? saleInfo.id : ''"
        disabled
      ></el-input>
    </div>
    <div
      class="flex mt-2 items-center"
      v-if="saleInfo"
    >
      <img
        :src="saleInfo.imageUrl"
        class="w-20 h-20"
      />
      <div class="flex-1 ml-2 leading-6 text-gray-600">
        <div>{{ saleInfo.productName }}({{ saleInfo.saleName }})</div>
        <div>商品ID:{{ saleInfo.id }}</div>
        <div>产品ID:{{ saleInfo.productId }}</div>
      </div>
    </div>
    <div
      v-show="saleInfo && saleInfo.uploader && saleInfo.uploader != userInfo.id"
      class="text-orange-300 leading-4 mt-2"
    >
      你不是该商品的上传人
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    saleInfo: Object,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {},
};
</script>
